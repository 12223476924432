jQuery(document).ready(function () {
  
  jQuery(".home-intro").imagesLoaded(function () {
    // Animate it for coolness
    jQuery(document).ready(function () {
      jQuery(".home-intro .col-first").addClass("inplace");
      jQuery(".home-intro .col-second").addClass("inplace");
    });
  });
});
